import React from 'react'

const RainDrop = ({ randoHundo, randoFiver, increment, direction }) => {

    return (
        <div
            className="drop"
            style={{
                [direction]: `${increment}%`,
                bottom: `${randoFiver + randoFiver - 1 + 100}%`,
                animationDelay: `0.${randoHundo}s`,
                animationDuration: `0.5${randoHundo}s`,
            }}
        >
            <div
                className="stem"
                style={{
                    animationDelay: `0.${randoHundo}s`,
                    animationDuration: `0.5${randoHundo}s`,
                }}
            >
            </div>
            <div
                className="splat"
                style={{
                    animationDelay: `0.${randoHundo}s`,
                    animationDuration: `0.5${randoHundo}s`,
                }}
            >
            </div>
        </div>
    )
}

export default RainDrop
