import React, { useState, useEffect } from 'react'
import CardGrid from './CardGrid'
import CardList from "./CardList"
import data from "./tmp/data.json"
import { Layout, Space } from "antd"
import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons"
import { Route, Switch, useLocation, useParams } from "react-router-dom"
import VideoPlay from './VideoPlay'
import Fallback from './Fallback'
import Licence from './Licence'
import CCFooter from './CCFooter'
import Headers from "./Headers"
import RainFall from '../effects/rain-fall'

const HersReal = "pYHV0gXjcr8"

const { Header, Content, Footer } = Layout

const Yukimi = () => {

    const [direction, setDirection] = useState("horizontal")
    const location = useLocation()
    const id = location.pathname.replace(/.*\//g, "")

    useEffect(() => {
        if (window.innerWidth < 600) {
            setDirection("vertical")
        }
    }, [])

    return (
        <Layout className="yukimi-layout">
            {
                id === HersReal
                ? (
                    <div className="rainfall rainy-day">
                        <RainFall />
                    </div>
                )
                : ""
            }
            <Header className="header">
                <Headers />
            </Header>

            <Content className="content-wrapper">
                <Switch>
                    <Route path="/video/:id">
                        <VideoPlay dataAll={data} />
                    </Route>

                    <Route path="/license">
                        <Licence />
                    </Route>

                    <Route path="/">
                        <section className="feed">
                            <div className="display-options">
                                <Space>
                                    <div
                                        style={{ color: direction === "horizontal" ? "#096dd9" : "black" }}
                                        onClick={() => {
                                            setDirection("horizontal")
                                        }}
                                    >
                                        <AppstoreOutlined />
                                    </div>
                                    <div
                                        style={{ color: direction === "vertical" ? "#096dd9" : "black" }}
                                        onClick={() => {
                                            setDirection("vertical")
                                        }}
                                    >
                                        <UnorderedListOutlined />
                                    </div>
                                </Space>
                            </div>
                            <div>
                                {
                                    direction === "vertical"
                                        ? <CardList dataAll={data} />
                                        : <CardGrid dataAll={data} />
                                }
                            </div>
                        </section>
                    </Route>

                    <Route>
                        <Fallback />
                    </Route>
                </Switch>
            </Content>

            <Footer style={{ textAlign: 'center' }}>
                <CCFooter />
            </Footer>
        </Layout>
    )
}

export default Yukimi
