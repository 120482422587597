import React from 'react'

const CCFooter = () => {

    return (
        <div>
            <a
                target="_blank"
                rel="license noopener noreferrer"
                href="http://creativecommons.org/licenses/by-nc-nd/4.0/"
            >
                <img
                    alt="Creative Commons License"
                    style={{ borderWidth: 0 }}
                    src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
                />
            </a>
            <br />
        This work is licensed under a&nbsp;
            <a
                target="_blank"
                rel="license noopener noreferrer"
                href="http://creativecommons.org/licenses/by-nc-nd/4.0/"
            >
                Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License
                </a>.
        </div>
    )
}

export default CCFooter
