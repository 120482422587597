import React, { useState, useEffect } from 'react'
import RainDrop from './rain-drop'
import "./rain-fall.scss"

const RainFall = () => {

    const [drops, setDrops] = useState([])
    console.log(drops)

    useEffect(() => {
        var inc = 0
        var list = []
        while (inc < 100) {
            let hundo = (Math.floor(Math.random() * (98 - 1 + 1) + 1))
            let fiver = (Math.floor(Math.random() * (5 - 2 + 1) + 2))
            inc += fiver

            list.push({ hundo, fiver, inc })
        }
        setDrops(list)
    }, [])

    return (
        <div className="back-row-toggle splat-toggle">
            <div className="rain front-row">
                {
                    drops.length
                        ? drops.map((e, i) => (
                            <RainDrop
                                key={i}
                                direction="left"
                                randoHundo={e.hundo}
                                randoFiver={e.fiver}
                                increment={e.inc}
                            />
                        ))
                        : ""
                }
            </div>
            <div className="rain back-row">
                {
                    drops.length
                        ? drops.map((e, i) => (
                            <RainDrop
                                key={i}
                                direction="right"
                                randoHundo={e.hundo}
                                randoFiver={e.fiver}
                                increment={e.inc}
                            />
                        ))
                        : ""
                }
            </div>
        </div>
    )
}

export default RainFall
