import React from 'react'
import { Popover, Card } from "antd"

import cc from "./assets/cc.png"
import by from "./assets/by.png"
import nc from "./assets/nc.png"
import sa from "./assets/sa.png"

import text from "./text.json"

const { Meta } = Card

const Condition = ({ avatar, title, content }) => {

    return (
        <Card>
            <Meta
                avatar={
                    avatar
                        ? <img className="small-icon" alt="avatar" src={avatar} />
                        : null
                }
                title={title}
                description={content}
            />
        </Card>
    )
}

const Licence = () => {

    return (
        <article className="licence">
            <section className="licence-header">
                <div className="licence-icon-group">
                    <img alt="cc" src={cc} />
                    <img alt="by" src={by} />
                    <img alt="nc" src={nc} />
                    <img alt="sa" src={sa} />
                </div>
                <h1>
                    Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0)
                </h1>
            </section>
            <section className="licence-content">
                <p>
                    This is a human-readable summary of (and not a substitute for)
                    the <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode" target="_blank" rel="noopener noreferrer">license</a>.
                    <Popover
                        placement="bottom"
                        title="Disclaimer"
                        content={
                            text.disclaimer.map((e, i) => (
                                <p key={i}>{e}</p>
                            ))
                        }
                    >
                        <a> Disclaimer.</a>
                    </Popover>
                </p>

                <h1>You are free to:</h1>
                <Condition
                    title="Share"
                    content={
                        <p>copy and redistribute the material in any medium or format</p>
                    }
                />
                <Condition
                    title="Adapt"
                    content={
                        <p>remix, transform, and build upon the material</p>
                    }
                />
                <p>
                    The licensor cannot revoke these freedoms as long as you follow the license terms.
                </p>

                <h1>Under the following terms:</h1>
                <Condition
                    avatar={by}
                    title="Attribution"
                    content={
                        <p>
                            You must give appropriate credit, provide a link to the license, and indicate if changes were made.
                            You may do so in any reasonable manner,
                            but not in any way that suggests the licensor endorses you or your use.
                        </p>
                    }
                />
                <Condition
                    avatar={nc}
                    title="NonCommercial"
                    content={
                        <p>
                            You may not use the material for commercial purposes.
                        </p>
                    }
                />
                <Condition
                    avatar={sa}
                    title="ShareAlike"
                    content={
                        <p>
                            If you remix, transform, or build upon the material,
                            you must distribute your contributions
                            under the same license as the original.
                        </p>
                    }
                />
                <Condition
                    title="No additional restrictions"
                    content={
                        <p>
                            You may not apply legal terms or technological measures
                            that legally restrict others from
                            doing anything the license permits.
                        </p>
                    }
                />

                <h1>Notices:</h1>
                <p>
                    You do not have to comply with the license for elements
                    of the material in the public domain or where your use
                    is permitted by an applicable exception or limitation.
                </p>
                <p>
                    No warranties are given.
                    The license may not give you all of the permissions necessary
                    for your intended use. For example, other rights such as
                    publicity, privacy, or moral rights may limit how you use the material.
                </p>
            </section>
        </article>
    )
}

export default Licence
