import React from 'react'
import { Card, Skeleton, Image, Tag, Space } from "antd"
import getThumbnail from "youtube-thumbnail"
import { PlayCircleOutlined, ClockCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"

const { Meta } = Card

const InfoCard = ({ data, lang = "jp", vertical }) => {

    const history = useHistory()

    const youtubeThumbnail = data ? getThumbnail(data.video) : null

    const thumbnail = (
        data
            ? <Image
                src={youtubeThumbnail?.medium.url}
                width={240}
                preview={false}
            />
            : <Skeleton.Button
                active
                size={100}
            />
    )

    const info = (
        data
            ? data[lang]
            : ({})
    )

    return (
        <article
            onClick={() => {
                if(data){
                    history.push(`/video/${data._id}`)
                }
            }}
        >
            <Card
                hoverable
                cover={vertical ? null : thumbnail}
                style={
                    vertical
                        ? { width: 1280, maxWidth: "95vw", height: "100%" }
                        : { width: 240, height: 280 }
                }
            >
                <Skeleton
                    loading={!data}
                    active
                    paragraph={{ rows: vertical ? 3 : 2 }}
                >
                    <Meta
                        className="card-meta"
                        style={{
                            fontSize: (
                                window.innerWidth >= 480
                                    ? "100%"
                                    : `${Math.floor(window.innerWidth / 4.8)}%`
                            )
                        }}
                        avatar={
                            vertical
                                ? (
                                    <img
                                        alt={data?.name}
                                        src={youtubeThumbnail?.medium.url}
                                        className="youtube-thumbnail"
                                    />
                                )
                                : ""
                        }
                        title={info.name}
                        description={
                            <>
                                <p>{info.artist}</p>
                                {/* <p>
                                {
                                    vertical && data && data.tag
                                        ? data.tag.map((e, i) => (
                                            <Tag key={i}>{e}</Tag>
                                        ))
                                        : ""
                                }
                            </p> */}
                                <Space>
                                    <span>
                                        <PlayCircleOutlined /> {data.view}
                                    </span>
                                    <span>
                                        <ClockCircleOutlined /> {data.time}
                                    </span>
                                </Space>
                            </>
                        }
                    />

                </Skeleton>
            </Card>
        </article>
    )
}

export default InfoCard

