import React from 'react'
import './App.scss'
import AppRouter from './components/AppRouter'
import GitHub from './components/GitHub'

const App = () => {

  return (
    <main>
      <AppRouter />
    </main>
  )
}

export default App
