import React from 'react'
import YouTube from 'react-youtube'
import { Card, Skeleton, Space, message, Modal } from "antd"
import { useParams } from "react-router-dom"
import Fallback from './Fallback'
import copy from 'copy-to-clipboard'
import { ShareAltOutlined, DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import download from "downloadjs"
import Licence from "./Licence"

const { Meta } = Card
const { confirm } = Modal

const VideoPlay = ({ dataAll, lang = "jp" }) => {

    const { id } = useParams()
    const data = dataAll?.find(e => e._id === id)

    if (dataAll && !data) return <Fallback />

    const vw95 = Math.floor(0.95 * window.innerWidth)
    const opt_width = Math.min(vw95, 1280)
    const opts = {
        width: `${opt_width}`,
        height: `${Math.floor((9 / 16) * opt_width)}`
    }

    const onDownload = () => {
        confirm({
            title: 'License',
            width: Math.min(Math.floor(0.95 * window.innerWidth), 1024),
            icon: <ExclamationCircleOutlined />,
            content: <Licence />,
            onOk() {
                download(`/download/${data?.link}`)
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve()
                    }, 1000)
                }).catch(() => console.log('Oops errors!'))
            },
            onCancel() { },
        })
    }

    return (
        <div>
            <Card
                style={{
                    width: 1280,
                    maxWidth: "95vw"
                }}
                cover={
                    data
                        ? <YouTube videoId={data._id} opts={opts} />
                        : null
                }
            >
                <Skeleton
                    loading={!data}
                    active
                    paragraph={{ rows: 2 }}
                >
                    <Meta
                        style={{color: "floralwhite"}}
                        title={data?.[lang]?.name}
                        description={
                            <>
                                <p>{data?.[lang]?.artist}</p>
                                <Space>
                                    <span
                                        style={{ fontSize: "150%" }}
                                        onClick={() => {
                                            copy(window.location.href)
                                            message.success("URL copied")
                                        }}
                                    >
                                        <ShareAltOutlined />
                                    </span>
                                    <span
                                        style={{ fontSize: "150%" }}
                                        onClick={onDownload}
                                    >
                                        <DownloadOutlined />
                                    </span>
                                </Space>
                            </>
                        }
                    />
                </Skeleton>
            </Card>
        </div>
    )
}

export default VideoPlay
