import React from 'react'
import {Space} from "antd"
import InfoCard from './InfoCard'

const CardList = ({dataAll}) => {

    return (
        <Space
            direction="vertical"
            size="small"
            style={{width: "inherit", maxWidth: "inherit"}}
        >
            {
                dataAll
                ? dataAll.map((e, i) => (
                    <InfoCard key={i} data={e} vertical />
                ))
                : new Array(10).fill(null).map((e, i) => (
                    <InfoCard data={e} key={i} vertical />
                ))
            }
        </Space>
    )
}

export default CardList
