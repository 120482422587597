import React from 'react'

const Fallback = () => {

    return (
        <div>
            Fallback page
        </div>
    )
}

export default Fallback
