import React from 'react'
import {
    Route, Switch,
    useHistory,
} from "react-router-dom"
import { PageHeader } from "antd"

const Headers = () => {

    const history = useHistory()

    return (
        <Switch>
            <Route exact path="/">
                <PageHeader
                    title="Piano"
                    subTitle="my works"
                />
            </Route>

            <Route path="/video/:id">
                <PageHeader
                    onBack={() => {
                        history.push("/")
                    }}
                    title="Video Player"
                    // breadcrumb={{routes: [
                    //     {
                    //         path: "/",
                    //         breadcrumbName: "Index",
                    //     },
                    //     {
                    //         path: null,
                    //         breadcrumbName: "Video Player",
                    //     },
                    // ]}}
                />
            </Route>

            <Route>
                <PageHeader
                    onBack={() => {
                        history.push("/")
                    }}
                    title="License"
                    // breadcrumb={{routes: [
                    //     {
                    //         path: "/",
                    //         breadcrumbName: "Index",
                    //     },
                    //     {
                    //         path: null,
                    //         breadcrumbName: "License",
                    //     },
                    // ]}}
                />
            </Route>
        </Switch>
    )
}

export default Headers
