import React from 'react'
import {
    BrowserRouter as Router,
    Route, Switch
} from "react-router-dom"
import Fallback from './Fallback'
import Yukimi from './Yukimi'

import "./Yukimi.scss"

const AppRouter = () => {

    return (
        <Router>
            <Switch>
                <Route path="/">
                    <Yukimi />
                </Route>

                <Route>
                    <Fallback />
                </Route>
            </Switch>
        </Router>
    )
}

export default AppRouter
