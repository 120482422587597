import React from 'react'
import {Space} from "antd"
import InfoCard from './InfoCard'

const CardGrid = ({dataAll}) => {

    return (
        <Space wrap
            size="middle"
        >
            {
                dataAll
                ? dataAll.map((e, i) => (
                    <InfoCard key={i} data={e} />
                ))
                : new Array(10).fill(null).map((e, i) => (
                    <InfoCard data={e} key={i} />
                ))
            }
        </Space>
    )
}

export default CardGrid
